<template>
  <v-container fluid class="fill-height login-style">
    <v-row dense justify="center">
      <!-- <v-col class="d-none d-lg-flex align-start"></v-col> -->
      <v-col cols="auto" class="align-center">
        <v-form ref="loginForm" v-model="doValid" lazy-validation>
          <v-card
            outlined
            class="card-bg-none card-border-none"
            :max-width="this.device.form.width"
          >
            <v-card-title class="justify-center white--text">{{
              title
            }}</v-card-title>
            <v-card-text class="py-0">
              <v-row dense>
                <v-col cols="12" class="my-4">
                  <v-divider color="white"></v-divider
                ></v-col>
                <v-spacer></v-spacer>
                <v-col cols="12">
                  <v-container>
                    <v-row dense justify="center">
                      <v-col cols="12">
                        <div
                          class="mr-2 subtitle-1 white--text align-self-center"
                        >
                          {{ formVars.email.label }}
                        </div>
                        <v-text-field
                          v-model="formVars.email.value"
                          :rules="formVars.email.rules"
                          background-color="white"
                          dense
                          filled
                          style="max-width: 100%"
                          hide-details="auto"
                          required
                          @keypress.enter.prevent="onSubmit()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <div
                          class="mr-2 subtitle-1 white--text align-self-center"
                        >
                          {{ formVars.password.label }}
                        </div>
                        <v-text-field
                          v-model="formVars.password.value"
                          :rules="formVars.password.rules"
                          type="password"
                          background-color="white"
                          dense
                          filled
                          hide-details="auto"
                          required
                          @keypress.enter.prevent="onSubmit()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox
                          v-model="formVars.remember.value"
                          :label="formVars.remember.label"
                          class="my-0 ck-icon-white"
                          dense
                          hide-details="auto"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" class="my-4">
                  <v-divider color="white"></v-divider
                ></v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
              <div class="my-4">
                <v-btn @click="onSubmit" class="body-1">送出</v-btn>
                <v-btn @click="onReset" class="ml-4 body-1">重填</v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-form>
        <!-- dialog -->
        <MsgDialog :dialogProps="dialog">
          <template v-slot:custom-card-actions>
            <v-btn
              color="green darken-1"
              outlined
              @click="clickDialogBtn('home')"
              v-if="dialog.btns.home"
              >回首頁</v-btn
            >
            <v-btn
              color="green darken-1"
              outlined
              @click="clickDialogBtn('login')"
              v-if="dialog.btns.login"
              >返回登入</v-btn
            >
            <v-btn
              color="green darken-1"
              outlined
              @click="clickDialogBtn('close')"
              v-if="dialog.btns.close"
              >關閉</v-btn
            >
            <v-btn
              color="green darken-1"
              outlined
              @click="clickDialogBtn('abort')"
              v-if="dialog.btns.abort"
              >跳出</v-btn
            >
          </template>
        </MsgDialog>
        <!-- dialog for processing end -->
      </v-col>
      <!-- <v-col class="d-none d-lg-flex align-end"></v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'; // namespacedHelpers
import deviceInfor from '@util/common/deviceInfor.js';
import routeHandler from '@util/common/routeHandler.js';
import MsgDialog from '@component/dialog/MsgDialog';

export default {
  name: 'UserLogin',
  components: { MsgDialog },
  data () {
    return {
      title: 'CRM 管理系統',
      device: null,
      dialog: {
        init: false,
        active: false,
        isClose: false, // 關閉訊息窗
        title: '',
        msg: '',
        process: false,
        persistent: true, // 預設都要點擊按鈕
        btns: {
          login: false, // 返回登入頁
          home: false, // 返回CRM首頁
          close: false, // 關閉訊息窗
          abort: false // 跳出(關閉訊息窗)
        }
      },
      doValid: true,
      formVars: {
        // username: { id: 'username', label: '帳號', value: '', rules: [val => !!val || '請輸入帳號', val => (val && val.length <= 10) || '帳號不得超過10碼'] },
        email: {
          id: 'email',
          label: '帳號',
          value: '',
          rules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || '確認您的帳號是否為email格式'
          ]
        },
        password: {
          id: 'password',
          label: '密碼',
          value: '',
          rules: [val => !!val || '請輸入密碼']
        },
        profile: { id: 'profile', label: '身分', value: 'user', rules: [val => !!val || '請選擇身分'] },
        remember: { id: 'remember', label: '記住我', value: false }
      },
      redirect: {
        success: '/home',
        fail: '/login'
      }
    };
  },
  watch: {
    // 監聽 OAutht處理狀態
    '$store.state.oauth.status': {
      handler (newStatus, oldStatus) {
        if (typeof oldStatus === 'undefined') {
          return;
        }
        // init dialog (含成功會直接關閉)
        const _dialog = this.$options.data().dialog;
        _dialog.width = this.device.dialog.width;
        // 異常需要顯示
        if (newStatus >= 2) { // 登入處理中
          _dialog.process = true;
          _dialog.persistent = true;
          _dialog.title = '登入驗證中，請稍後...';
          this.$set(this, 'dialog', _dialog);
          return;
        }
        if (newStatus < 0) { // 登入失敗
          _dialog.title = '登入驗證失敗';
          _dialog.msg = this.$store.state.oauth.msg;
          _dialog.btns.close = true;
          this.$set(this, 'dialog', _dialog);
        }
        // 登入成功
        // console.log('登入成功');
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * function => store process
     */
    ...mapGetters({
      loggerProfile: 'oauth/loggerProfile'
    }),
    ...mapActions({
      doLogin: 'oauth/doLogin'
    }),
    /**
     * [initSet] 初始化
     */
    initSet () {
      // loginForm width
      this.device = deviceInfor.getInfor(this);
      this.device.form = {};
      this.device.dialog = { width: '40vw' };
      // console.log(this.device);
      switch (this.device.breakpoint) {
        case 'xs':
          this.device.form.width = '';
          this.device.form.width = '';
          break;
        case 'sm':
          this.device.form.width = '50vw';
          break;
        case 'md':
          this.device.form.width = '40vw';
          break;
        default:
          this.device.form.width = '30vw';
          break;
      }
    },
    /**
     * [onSubmit]
     */
    onSubmit () {
      // console.log('[login][onsubmit] ...');
      if (this.$refs.loginForm.validate()) {
        const params = {
          caller: this.formVars.profile.value,
          email: this.formVars.email.value,
          password: this.formVars.password.value,
          remember: this.formVars.remember.value,
          redirect: this.redirect.success
        };

        // login OAuth
        this.doLogin(params);
      }
    },
    /**
     * [onReset]
     */
    onReset () {
      this.$refs.loginForm.resetValidation();
      this.formVars = this.$options.data().formVars;
    },
    /**
     * [clickDialogBtn]
     */
    clickDialogBtn (tg = '') {
      const param = { path: '' };
      const _dialog = this.$options.data().dialog;
      switch (tg) {
        case 'home':
          _dialog.isClose = true;
          param.path = '/home';
          break;
        case 'login':
          _dialog.isClose = true;
          param.path = '/login';
          break;
        case 'close':
          _dialog.isClose = true;
          break;
        case 'abort':
          window.close();
          break;
      }
      this.$set(this, 'dialog', _dialog);
      // redirect
      // console.log(param);
      if (param.path !== '') {
        routeHandler.nextRoute(this, param);
      }
    }
  },
  created () {
    // console.log('[UserLogin][created] ...');
    this.initSet();
  }
};
</script>
<style scoped>
/* .color-opacity {
  opacity: 100 !important;
} */
</style>
