/**
 * deviceInfor.js
 * 運行裝置相關
 */

export default {
  /**
   * [getInfor]
   * @param {*} _this
   */
  getInfor (_this) {
    const device = {};
    // mobile / width / height
    device.mobile = _this.$vuetify.breakpoint.mobile;
    device.width = _this.$vuetify.breakpoint.width;
    device.height = _this.$vuetify.breakpoint.height;
    // breakpoint
    ['xl', 'lg', 'md', 'sm', 'xs'].forEach(function (k) {
      if (_this.$vuetify.breakpoint[k]) {
        device.breakpoint = k;
      }
    });
    return device;
  }
};
