<template>
  <UserLoginForm></UserLoginForm>
</template>
<script>
import UserLoginForm from '@/components/form/UserLogin.vue';

export default {
  components: { UserLoginForm },
  data () {
    return {
      title: 'Login'
    };
  },
  created () {
    // console.log('[login][created] ...');
  }
};
</script>
