/**
 * routeHandler.js
 * 路由相關
 */
export default {
  /**
   * [nextRoute]
   * @param Object _this
   * @param Object param
   */
  nextRoute (_this = null, param = null) {
    if (!_this || !param || !param.path) {
      return false;
    }
    // next
    _this.$router
      .push({
        path: param.path
      })
      .catch((error) => {
        // Vue-route3.1版本之後，重複點擊or來源目的連結相同會報 error: NavigationDuplicated，要排除不動作即可
        if (error.name !== 'NavigationDuplicated') {
          console.log(error);
          return false;
        }
      });
  }
};
